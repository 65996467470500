import TweetListItemComponent from './TweetListItem'
import TwemojiComponent from './Twemoji'
import MediaComponent from './Media'
import App from './App'
import Vue from 'vue'

Vue.component('tweet-list-item', TweetListItemComponent)
Vue.component('twemoji', TwemojiComponent)
Vue.component('media', MediaComponent)

const vm = new Vue(App).$mount("#app") // eslint-disable-line
