<template>
<!-- 拡散した人のリスト -->
<div v-cloak>
    <h3 style="padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1); margin:0 ">拡散したユーザー</h3>
    <router-link v-for="sharer_id in sharers" :to="'/u/' + encodeUserId(sharer_id)" class="text-muted" :key="sharer_id" style="display: block; padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1)">
        @{{ sharer_id }}
    </router-link>
</div>
</template>

<script>
import { encodeUserId } from './utils'

export default {
    data () {
        return { sharers: [] }
    },
    async beforeRouteEnter(to, from, next) {
        var res = await fetch(`/api/status/${ to.params.id }`)
        if (res.status == 200) {
            var tweet = await res.json()
            next(vm => { vm.sharers = tweet.shared_by || [] })
        } else {
            alert(`status ${res.status}\n/api/status/${tweet.id}`)
            next(vm => { vm.sharers = [''] })
        }
    },
    methods: {
        encodeUserId: encodeUserId,
    },
}
</script>
