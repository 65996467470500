<template>
<!-- いいねしたツイートのリスト -->
<div v-cloak>
  <h3 style="padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1); margin: 0">@{{ content_user_id }}</h3>
  <div style="padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1); margin: 0">
    <router-link :to="'/u/' + encodeUserId(content_user_id)">壺焼き</router-link>
    <b>いいね</b>
  </div>
  <div v-if="liked_tweets.length == 0" style="padding: 0.5em">
    いいねした壺焼きはまだありません。
  </div>
  <tweet-list-item v-for="tweet in liked_tweets" class="tweet" :key="tweet.id" :tweet="tweet" :likes="likes"></tweet-list-item>
</div>
</template>

<script>
  import { encodeUserId } from './utils'

export default {
    data () {
        return {
            content_user_id: "",
            liked_tweets: [],
            likes: [],
        }
    },
    async beforeRouteEnter(to, from, next) {
        var data = await (await fetch(`/api/u/${encodeURI(to.params.id)}/likes?with_tweets=1`)).json()
        next(vm => {
            vm.content_user_id = to.params.id.replace(/~/g, '/')
            vm.liked_tweets = data.liked_tweets
            vm.likes = data.likes
        })
    },
    methods: {
        encodeUserId: encodeUserId,
    },
}
</script>
