<template>
<!-- いいねした人のリスト -->
<div v-cloak>
    <h3 style="padding: 0.5em; border-bottom: 1px solid var(--border); margin:0 ">いいねしたユーザー</h3>
    <router-link :to="'/u/' + encodeUserId(liker_id)" class="text-muted" v-for="liker_id in likers" :key="liker_id" style="display: block; padding: 0.5em; border-bottom: 1px solid var(--border)">
        @{{ liker_id }}
    </router-link>
</div>
</template>

<script>
import { encodeUserId } from './utils'

export default {
    data () {
        return { likers: [] }
    },
    async beforeRouteEnter(to, from, next) {
        var res = await fetch(`/api/status/${ to.params.id }/likers`)
        if (res.status == 200) {
            var ls = await res.json()
            next(vm => { vm.likers = ls })
        } else {
            alert(`status ${res.status}`)
            next(vm => { vm.likers = [] })
        }
    },
    methods: {
        encodeUserId: encodeUserId,
    },
}
</script>
