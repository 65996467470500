<style>
  .search-box {
    margin: 0.5em; padding: 0.5em 1em; border-radius: 32px; background-color: #eee;
  }

  .search-button {
    appearance: none; float: right; border: none; width: 32px; height: 32px;
  }

  input#query {
      box-sizing: border-box;
      height: 32px;
      font-size: 16px;
      border: none;
      background-color: transparent;
      width: calc(100% - 32px)
  }

  /* 壺焼きリストに混ぜて違和感のないブロック要素 */
  .list-item2 {
      padding-left: 8px !important;
      padding-right: 8px !important;
      border-bottom: 1px solid rgba(0,0,0,0.2);
  }

  @media (prefers-color-scheme: dark) {
    .search-box, .search-button { background-color: #444; }
  }
</style>

<template>
<div>
  <div class="list-item2">
    <div class="search-box">
      <input id="query" type="text" v-model="query" @keydown.enter="search">
      <button class="search-button" @click="search"><twemoji :codepoint="0x1f50d"></twemoji></button>
    </div>
    <div v-if="isSending" style="text-align: center; font-size: 24px">
      <twemoji :codepoint="0x23F3" title="検索中"></twemoji>
    </div>
    <div>lastQuery: {{lastQuery}}</div>
  </div>
  <div v-if="lastQuery == ''">
    <!-- welcome -->
    <div><b>探す:</b></div>
    <a class="list-item list-item2" href="/by-year/">1年ごと</a>
  </div>
  <div v-for="tweet in tweets" class="tweet" v-cloak :key="tweet.id">
    <tweet-list-item :tweet="tweet" :likes="likes"></tweet-list-item>
  </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            tweets: [],
            likes: [],
            query: "",
            lastQuery: "",
            isSending: false,
        }
    },
    async beforeRouteEnter(to, from, next) {
        var likes = await (await fetch("/likes")).json()
        next(vm => {
            vm.likes = likes
        })
    },
    methods: {
        async search() {
//            this.query = document.getElementById("query").value
            if (this.isSending)
                return;
            if (this.query == "") {
                this.tweets = [];
                return;
            }
            this.tweets = [];

            var form = new FormData()
            form.set('query', this.query)
            this.isSending = true
            fetch('/api/search', { method: 'POST', body: form }).then(async d =>  {
                if (d.status == 200) {
                    const data = await d.json()
                    this.tweets = data.tweets
                    this.lastQuery = data.query
                } else {
                    var text = await d.text()
                    alert(text)
                }
                this.isSending = false
            })
        },
    }
}
</script>
