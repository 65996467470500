<style>
  .muted-info-row { font-size: 90%; font-weight: bold; color: gray }
  @media (prefers-color-scheme: dark) {
      .muted-info-row { color : #bbb }
  }
</style>

<template>
<!-- @dragenter.prevent -->
<div draggable
     @dragstart="startDrag($event, tweet)"
     @drop.prevent="onDrop($event, tweet)"
     @dragover.prevent>
  <router-link style="padding: 0.5em" :to="'/status/' + tweet.id" class="list-item">
    <!-- ピン留め情報 -->
    <div v-if="pinned" class="muted-info-row">
      <twemoji :codepoint="0x1f4cc"></twemoji>
      ピン留めされた壺焼き
    </div>
    <!-- 閲覧ユーザーが拡散した壺焼きか -->
    <div v-if="is_shared" class="muted-info-row">
      <twemoji :codepoint="0x1f4a6" title="拡散"></twemoji>
      拡散済み
    </div>
    <!-- 投稿者と投稿時刻 -->
    <div>
      <b class="text-muted" style="font-family: monospace; font-size: 16px">@{{ screenName }} </b>
      <span class="time">{{ renderDate(tweet.time) }}</span>
    </div>
    <!-- 返信先情報 -->
    <div v-if="tweet.in_reply_to" class="muted-info-row">
      <router-link :to="'/status/' + tweet.in_reply_to">
        @9Sh2TWJN への返信
      </router-link>
    </div>
    <!-- 本文 -->
    <div class="tweet-text" v-html="renderTweetText(tweet)"></div>
    <!-- 添付メディア -->
    <media v-for="media in tweet.media" :media="media" :key="media"></media>
    <!-- コマンドボックス -->
    <div v-if="!tweet.foreign" style="margin-top: .5em">
      <router-link style="font-size: 90%; text-align: left; width: 33%; display: inline-block"
                   :to="'/status/' + tweet.id + '/reply'">
        <twemoji :codepoint="0x1f5e8" title="返信"></twemoji>
      </router-link>
      <span class="text-muted" style="box-sizing: border-box; font-size: 90%; width: 33%; display: inline-block; text-align: left">
        <twemoji :codepoint="0x1f4a6" title="拡散"></twemoji>
        {{sharedCount}}
      </span>
      <button class="like-button"
              style="text-align: left; padding: 0; box-sizing: border-box; width: 33%; display: inline; "
              @click.prevent="toggleLike(tweet)">
        <span v-if="isLiked">
          <twemoji :codepoint="0x2764" title="いいね"></twemoji>
        </span>
        <span v-else>
          <twemoji :codepoint="0x1f90d" title="いいね"></twemoji>
        </span>
        {{tweet.nlikes}}
      </button>
    </div>
  </router-link>
</div>
</template>

<script>
import { renderMedia, renderDate, renderTweetText } from './utils'

export default {
    props: ["tweet", "pinned", "likes", "is_shared"],
    data() {
        return {
            r_likes: [], /* reactive likes */
        }
    },
    created () {
        this.r_likes = this.likes
    },
    computed: {
        screenName() {
            return this.tweet.screen_name || "9Sh2TWJN"
        },
        mediaHtml() {
            return renderMedia(this.tweet)
        },
        isLiked() {
            return this.r_likes.includes(this.tweet.id)
        },
        sharedCount() {
            if (this.tweet.shared_by)
                return this.tweet.shared_by.length
            else
                return 0
        },
    },
    methods: {
        async onDrop(ev, targetTweet) {
            const id = ev.dataTransfer.getData('tweet_id')

            if (id == targetTweet.id) {
                // circular drop ignored
                return
            }

            var fd = new FormData()
            fd.set('id', id)
            fd.set('in_reply_to', targetTweet.id)
            var res = await fetch('/api/amend-reply', { method: 'POST', body: fd })
            if (res.status != 200) {
                const msg = await res.text()
                alert(`${res.status} エラー。\n${msg}`)
            }
        },
        startDrag(ev, tweet) {
            ev.dataTransfer.dropEffect = 'move'
            ev.dataTransfer.effectAllowed = 'move'
            ev.dataTransfer.setData('tweet_id', tweet.id)
        },
        renderDate: renderDate,
        renderTweetText: renderTweetText,
        toggleLike(tweet) {
            if (this.isLiked) {
                this.r_likes = this.r_likes.filter(id => id != tweet.id)
                tweet.nlikes -= 1
                this.postUnlike(tweet.id)
            } else {
                this.r_likes.push(tweet.id)
                tweet.nlikes += 1
                this.postLike(tweet.id)
            }
        },
        postLike(tweetId) {
            var fd = new FormData()
            fd.set('id', tweetId)
            fetch('/like', { method: 'POST', body: fd })
        },
        postUnlike(tweetId) {
            var fd = new FormData()
            fd.set('id', tweetId)
            fetch('/unlike', { method: 'POST', body: fd })
        },
    },
}
</script>
