<style>
  @media (prefers-color-scheme: dark) {
  }
</style>

<template>
<div>
  <div style="padding-left: 8px; padding-right: 8px; border-bottom: 1px solid rgba(0,0,0,0.2);">
    <a v-for="year in years" :key="year" class="list-item" :href="'/by-year/' + year">{{ year }}年 の壺焼き</a>
  </div>
</div>
</template>

<script>
export default {
    data() {
        const startYear = 2021
        const thisYear = new Date().getYear() + 1900
        const years = [...Array(thisYear - startYear + 1).keys()].map(v => v + startYear).reverse()

        return {
            years: years,
        }
    },
    // async beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //     })
    // },
    methods: {
    }
}
</script>
