<template>
<!-- <img class="emoji" draggable="false" :alt="htmlEntity" :src="cdnUrl"> -->
<span class="emoji">{{htmlEntity}}</span>
</template>

<script>
export default {
    props: ['codepoint'],
    computed: {
        htmlEntity() {
            let str = String.fromCodePoint(this.codepoint)
            // Unicodeに絵文字が定義されていて、そのコードポイントがア
            // ストラルプレーンにない場合、Variation Selector-16
            // (U+FE0F)が必要になる。
            if (this.codepoint <= 0xffff) {
                str += String.fromCodePoint(0xfe0f) // Variation Selector-16
            }
            return str
        },
        cdnUrl() {
            const hex = this.codepoint.toString(16)
            // return `https://twemoji.maxcdn.com/v/13.1.0/svg/${hex}.svg`
            return `/twemoji/assets/svg/${hex}.svg`
        },
    },
}
</script>
