<template>
<!-- 投稿フォーム -->
<div v-cloak style="margin: 0.5em">
  <div>
    <label>In reply to: <input type="text" v-model="tweet.in_reply_to"></label>
  </div>
  <div>
    <textarea v-model="tweet.text" :disabled="isSending"></textarea>
  </div>
  <div>
    <label for="file">Media: </label>
    <input type="file" id="file" accept="image/png, image/jpeg, video/mp4" @change="onMediaFileSelected" :disabled="isSending">
  </div>
  <media v-for="media in tweet.media" :media="media" :key="media"></media>
  <div>
    <img v-if="file && file.type.startsWith('image/')"
         :src="media_preview_url"
         style="max-width: 160px; max-height: 160px">
    <video v-if="file && file.type.startsWith('video/')"
           :src="media_preview_url"
           style="max-width: 160px; max-height: 160px">
    </video>
  </div>
  <div>
    <button @click="submitMessage" :disabled="isSending">投稿</button>
  </div>
</div>
</template>

<script>
import { renderMedia } from './utils'

export default {
    data() {
        return {
            tweet: { text: "", in_reply_to: "" },
            media_preview_url: "",
            file: null,
            isSending: false,
        }
    },
    async beforeRouteEnter(to, from, next) {
        const tweet = await (await fetch("/api/status/" + to.params.id)).json()
        
        next(vm =>  {
            if (!tweet.in_reply_to)
                tweet.in_reply_to = ""
            vm.tweet = tweet

            const cb = function(event){
                console.log('cb called')
                var items = (event.clipboardData ||
                             event.originalEvent.clipboardData).items
                for (var index in items) {
                    var item = items[index]
                    if (item.kind === 'file') {
                        var blob = item.getAsFile()
                        var reader = new FileReader()
                        reader.onload = function(event){
                            vm.file = blob
                            vm.media_preview_url = event.target.result
                        }
                        reader.readAsDataURL(blob)
                    }
                }
            }
            document.onpaste = cb
            console.log('paste callback added')
            // vm.pasteCallback = cb
        })
    },
    beforeRouteLeave(to, from, next) {
        document.onpaste = null
        // document.removeEventListener('onpaste', this.pasteCallback)
        // console.log('paste callback removed')
        next()
    },
    methods: {
        renderMedia: renderMedia,
        onMediaFileSelected(ev) {
            var file = ev.target.files[0]
            this.file = file
            
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => { // 意図的なthisのキャプチャ。
                this.media_preview_url = reader.result
            }
        },
        submitMessage() {
            var form = new FormData()
            form.set('text', this.tweet.text)
            form.set('in_reply_to', this.tweet.in_reply_to)
            if (this.file)
                form.set('file', this.file)
            this.isSending = true
            fetch(`/api/status/${this.tweet.id}/amend`, { method: 'POST', body: form })
                .then(async d => {
                    this.isSending = false
                    if (d.status == 200) {
                        this.$router.push(`/status/${this.tweet.id}`)
                    } else {
                        var text = await d.text()
                        alert(text)
                    }
                })
        },
    }
}
</script>
