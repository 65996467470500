<template>
<!-- 単一壺焼き表示 -->
<div>
    <tweet-list-item v-for="tweet in references" class="tweet" :key="tweet.id" :tweet="tweet" :likes="likes"></tweet-list-item>
    <div v-if="references.length != 0" class="tl-space"></div>
    <div class="tweet" v-cloak style="padding: 0.5em">
        <div>
            <b class="text-muted">@{{tweet.screen_name || "9Sh2TWJN"}}</b>
            <div class="button-box">
                <a class="icon-link" href="#" @click.prevent="pinTweet(tweet.id)" style="margin: 0 .5em">
                    <twemoji :codepoint="0x1F4CC" title="ピン留め"></twemoji>
                </a>
                <router-link :to="'/status/' + tweet.id + '/edit'" style="margin: 0 0.5em">
                    <twemoji :codepoint="0x2712" title="編集"></twemoji>
                </router-link>
                <a class="icon-link" href="#" @click.prevent="postDelete(tweet.id)" style="margin: 0 .5em">
                    <twemoji :codepoint="0x1f5d1" title="削除"></twemoji>
                </a>
            </div>
        </div>
        <div v-if="tweet.shared_by" style="font-size: 90%">
            <twemoji :codepoint="0x1f4a6" title="拡散"></twemoji>
            <b class="text-muted">
                <router-link v-for="sharer_id in tweet.shared_by" :to="'/u/' + encodeUserId(sharer_id)" :key="sharer_id">
                    @{{sharer_id}}さん
                </router-link>
                が拡散しました
            </b>
        </div>
        <router-link v-if="tweet.in_reply_to" :to="'/status/' + tweet.in_reply_to">@9Sh2TWJN への返信</router-link>
        <div class="tweet-text" v-html="renderTweetText(tweet)" style="font-size: 125%"></div>
        <media v-for="media in tweet.media" :media="media" :key="media"></media>
        <div><a class="time" :href="'/status/' + tweet.id">{{ renderDate(tweet.time) }}</a></div>
        <div class="tweet-stat">
            <router-link v-if="tweet.shared_by" :to="'/status/' + tweet.id + '/sharers'" class="likers-link">
                <span style="font-weight: bold">{{tweet.shared_by.length}}</span>
                <span class="text-muted"> 拡散 </span>
                &emsp;
            </router-link>
            <router-link :to="'/status/' + tweet.id + '/likers'" class="likers-link">
                <span style="font-weight: bold">{{tweet.nlikes}}</span>
                <span class="text-muted"> いいね</span>
            </router-link>
        </div>
<div style="margin-top: 0.5em; font-size: 20px">
        <router-link style="box-sizing: border-box; text-align: left; display: inline-block; width: 50%" :to="'/status/' + tweet.id + '/reply'"><twemoji :codepoint="0x1f5e8" title="返信"></twemoji></router-link><button class="like-button" style="font-size: 20px; padding: 0; appearance: none; box-sizing: border-box; text-align: left; display: inline-block; width: 50%" @click="toggleLike(tweet)">
            <span v-if="isLiked">
                <twemoji :codepoint="0x2764"></twemoji>
            </span>
            <span v-else>
                <twemoji :codepoint="0x1f90d"></twemoji>
            </span>
        </button>
</div>
    </div>
    <div v-if="replies.length != 0" class="tl-space"></div>
    <tweet-list-item v-for="tweet in replies" class="tweet" :key="tweet.id" :tweet="tweet" :likes="likes"></tweet-list-item>
</div>
</template>

<script>
import { renderMedia, renderTweetText, renderDate, encodeUserId } from './utils'

export default {
    data () {
        return {
            tweet: {
                text: "",
                id: null,
                media: [],
                nlikes: null,
            },
            replies: [],
            references: [],
            likes: []
        }
    },
    async beforeRouteEnter(to, from, next) {
        const tweet = await (await fetch("/api/status/" + to.params.id)).json()
        var likes = await (await fetch('/likes')).json()
        var replies = tweet.replies ? tweet.replies : []
        var references = []

        let t = tweet
        while (t.in_reply_to) {
            t = await (await fetch("/api/status/" + t.in_reply_to)).json()
            references.unshift(t)
        }

        next(vm =>  {
            vm.tweet = tweet
            vm.replies = replies
            vm.references = references
            vm.likes = likes
        })
    },
    async beforeRouteUpdate(to, from, next) {
        const tweet = await (await fetch("/api/status/" + to.params.id)).json()
        var likes = await (await fetch('/likes')).json()
        var replies = tweet.replies ? tweet.replies : []

        this.tweet = tweet
        this.replies = replies
        this.references = []
        this.likes = likes
        next()
    },
    computed: {
        mediaHtml() {
            return renderMedia(this.tweet);
        },
        isLiked() {
            return this.likes.includes(this.tweet.id)
        },
    },
    methods: {
        pinTweet(id) {
            fetch(`/pin?id=${id}`)
                .then(r => {
                    if (r.status != 200)
                        alert(`${r.status} エラー`)
                    else
                        this.$router.push("/")
                })
        },
        renderTweetText: renderTweetText,
        renderDate: renderDate,
        async postDelete(tweetId) {
            if (!window.confirm("本当に削除しますか？")) {
                return
            }
            var r = await fetch('/login')
            if (r.status == 401) {
                alert("投稿するにはログインする必要があります。")
                return
            } else if (r.status != 200) {
                alert(`${r.status} error`)
                return
            }
            var fd = new FormData()
            fd.set('id', tweetId)
            fetch('/delete', { method: 'POST', body: fd }).then(d => {
                if (d.status != 200) {
                    console.log(d)
                    alert("could not delete")
                } else
                    this.$router.push("/")
            })
        },
        toggleLike(tweet) {
            if (this.isLiked) {
                this.likes = this.likes.filter(id => id != tweet.id)
                tweet.nlikes -= 1
                this.postUnlike(tweet.id)
            } else {
                this.likes.push(tweet.id)
                tweet.nlikes += 1
                this.postLike(tweet.id)
            }
        },
        postLike(tweetId) {
            var fd = new FormData()
            fd.set('id', tweetId)
            fetch('/like', { method: 'POST', body: fd }).then(d => console.log(d))
        },
        postUnlike(tweetId) {
            var fd = new FormData()
            fd.set('id', tweetId)
            fetch('/unlike', { method: 'POST', body: fd }).then(d => console.log(d))
        },
        encodeUserId: encodeUserId,
    },
}
</script>
