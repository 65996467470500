import { render, staticRenderFns } from "./TweetListItem.vue?vue&type=template&id=390f464a&"
import script from "./TweetListItem.vue?vue&type=script&lang=js&"
export * from "./TweetListItem.vue?vue&type=script&lang=js&"
import style0 from "./TweetListItem.vue?vue&type=style&index=0&id=390f464a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../.nvm/versions/node/v18.8.0/lib/node_modules/@vue/cli-service-global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports