<template>
<!-- 投稿フォーム -->
<div v-cloak style="margin: 0.5em">
  <label>In reply to: <input type="text" v-model="tweet.in_reply_to"></label>
  <div>
    <textarea v-model="tweet.text" :disabled="isSending"></textarea>
  </div>
  <div>
    <label for="file">Media: </label>
    <input type="file" id="file" accept="image/png, image/jpeg, video/mp4" @change="onMediaFileSelected" :disabled="isSending">
  </div>
  <div v-html="renderMedia(tweet)"></div>
  <div>
    <img :src="media_preview_url" style="max-width: 320px">
  </div>
  <div>
    <button @click="submitMessage" :disabled="isSending">投稿</button>
  </div>
</div>
</template>

<script>
import { renderMedia } from './utils'

export default {
    data() {
        return {
            tweet: { text: "", in_reply_to: "" },
            media_preview_url: "",
            file: null,
            isSending: false,
        }
    },
    async beforeRouteEnter(to, from, next) {
        const tweet = await (await fetch("/api/status/" + to.params.id)).json()

        next(vm =>  {
            vm.tweet.in_reply_to = tweet.id
        })
    },
    methods: {
        renderMedia: renderMedia,
        onMediaFileSelected(ev) {
            var file = ev.target.files[0]
            this.file = file

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => { // 意図的なthisのキャプチャ。
                this.media_preview_url = reader.result
            }
        },
        async submitMessage() {
            var r = await fetch('/login')
            if (r.status == 401) {
                alert("投稿するにはログインする必要があります。")
                return
            } else if (r.status != 200) {
                alert(`${r.status} error`)
                return
            }
            var form = new FormData()
            form.set('text', this.tweet.text)
            form.set('in_reply_to', this.tweet.in_reply_to)
            if (this.file)
                form.set('file', this.file)
            this.isSending = true
            fetch(`/tweet`, { method: 'POST', body: form })
                .then(d => {
                    this.isSending = false
                    if (d.status != 200) {
                        alert(`${d.status} エラー`)
                    } else {
                        this.$router.push(`/`)
                        // 壺焼きのページに飛びたいがIDがわからない。
                    }
                })
        },
    }
}
</script>
