<style scoped>
  video { box-sizing: border-box; max-width: 100%; max-height: 360px }
  img { box-sizing: border-box; max-width: 100%; max-height: 360px }
</style>

<template>
<video v-if="isVideo" :src="`/standard/${id}.mp4`" controls></video>
<a v-else-if="isImage" :href="`/media/${media}`"><img class="thumbnail" :src="`/thumbnails/${thumb}`"></a>
</template>

<script>
export default {
    props: ['media'],
    created() {
    },
    computed: {
        isVideo() {
            return !!(/\.mp4$/i.exec(this.media))
        },
        isImage() {
            return !this.isVideo
        },
        id() {
            return this.media.replace(/\.\w+/, "")
        },
        thumb() {
            return this.media.replace(/\.\w+/, ".jpg")
        },
    },
}
</script>
