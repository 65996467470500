<style>
  .tl-space { height: .9em; background-color: #eee; border-bottom: 1px solid rgba(0,0,0,0.2) }
  
  @media (prefers-color-scheme: dark) {
  .tl-space { background-color: #111; border-bottom-color: rgba(255,255,255,0.2) }
  }
</style>

<template>
  <div>
    <!-- 壺焼きのリスト -->
    <div v-if="pinned_tweet" class="tweet">
      <tweet-list-item :tweet="pinned_tweet" :pinned="true" :likes="likes"></tweet-list-item>
    </div>
    <div class="tl-space"></div>
    <div v-for="tweet in tweets_and_replies" class="tweet" v-cloak :key="tweet.id">
      <tweet-list-item :tweet="tweet" :likes="likes"></tweet-list-item>
    </div>
    <div style="padding: .5em; height: 100px; text-align: center">{{ bottom_message }}</div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  async beforeRouteEnter(to, from, next) {
    var likes = await (await fetch("/likes")).json();
    var data = await (await fetch("/timeline")).json();
    next(vm => {
      $(window).scroll(function() {
        if (
          vm.bottom_message == "Loading more tsuboyaki..." ||
          vm.bottom_message == "End of timeline."
        )
          return;
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height() - 100
        ) {
          vm.bottom_message = "Loading more tsuboyaki...";
          vm.loadOlderAsync().then(result => {
            if (result == false) {
              vm.bottom_message = "End of timeline.";
            } else {
              vm.bottom_message = "";
            }
          });
        }
      });
      console.log("on scroll");

      var pinned_id = data.pinned_tweet ? data.pinned_tweet.id : null;
      vm.pinned_tweet = data.pinned_tweet;
      vm.tweets = data.tweets.filter(t => !t.in_reply_to && t.id != pinned_id);
      vm.tweets_and_replies = data.tweets.filter(t => t.id != pinned_id);
      vm.likes = likes;
    });
  },
  beforeRouteLeave(to, from, next) {
    $(window).off();
    console.log("off scroll");
    next();
  },
  data() {
    return {
      tweets: [],
      tweets_and_replies: [],
      pinned_tweet: null,
      bottom_message: ""
    };
  },
  methods: {
    isLiked(t) {
      return this.likes.includes(t.id);
    },
    async loadNewerAsync() {
      if (this.tweets.length == 0) {
        this.loadTimelineAsync();
      } else {
        var data = await (
          await fetch(`/timeline?newer_than=${this.tweets[0].id}`)
        ).json();
        if (data.tweets.length == 0) {
          alert("No newer tweets");
          return;
        }
        this.tweets = data.tweets.concat(this.tweets);
      }
    },
    async loadOlderAsync() {
      if (this.tweets.length == 0) {
        this.loadTimelineAsync();
      } else {
        var data = await (
          await fetch(
            `/timeline?older_than=${this.tweets[this.tweets.length - 1].id}`
          )
        ).json();
        if (data.tweets.length == 0) {
          return false;
        }
        this.tweets = this.tweets.concat(data.tweets);
        this.tweets_and_replies = this.tweets_and_replies.concat(data.tweets);
      }
      return true;
    },
    async loadTimelineAsync() {
      var likes = await (await fetch("/likes")).json();
      var data = await (await fetch("/timeline")).json();
      this.tweets = data.tweets;
      this.likes = likes;
    }
  }
};
</script>
