<style>
  @media (prefers-color-scheme: dark) {
  }
</style>

<template>
<div>
  <div style="padding-left: 8px; padding-right: 8px; border-bottom: 1px solid rgba(0,0,0,0.2);">
    <h3>{{ year }}年 の壺焼き({{ tweets.length }})</h3>
  </div>
  <div v-for="tweet in tweets" class="tweet" v-cloak :key="tweet.id">
    <tweet-list-item :tweet="tweet" :likes="likes"></tweet-list-item>
  </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            tweets: [],
            likes: [],
            year: null,
        }
    },
    async beforeRouteEnter(to, from, next) {
        var likes = await (await fetch("/likes")).json()
        
        const form = new FormData()
        form.set('start', `${to.params.year}01010000`)
        form.set('end_inclusive', `${to.params.year}12312359`)
        var data = await (await fetch("/api/search-by-timespan", { method: "POST", body: form})).json()
        next(vm => {
            vm.likes = likes
            vm.tweets = data.tweets
            vm.year = to.params.year
        })
    },
    methods: {
    }
}
</script>
