//import twemoji from 'twemoji'

export function encodeUserId(id){
    return encodeURI(id.replace(/\//g, '~'))
}

export function escapeHtml(html){
    var text = document.createTextNode(html);
    var p = document.createElement('p');
    p.appendChild(text);
    return p.innerHTML;
}

export function renderTweetText(tweet) {
    var t = tweet.text

    t = t.replace(/[\u{13000}-\u{1342F}]+/ug, m => `<span class="egyptian-hieroglyphs">${m}</span>`)

    let wrapper = wrap(t)
    trim(wrapper)

    for (var i = 0; i < wrapper.children.length; i++) {
        if (wrapper.children[i].tagName == "BLOCKQUOTE") {
            const bq = wrapper.children[i]
            if (bq.attributes["cite"]) {
                const cite = escapeHtml(bq.attributes['cite'].value)
                bq.innerHTML = bq.innerHTML + `\n<a style="display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis" href="${cite}" class='cite-link' title="${cite}">${cite}</a>`
            }
        }
    }

    return wrapper.innerHTML;
}

export function wrap(html) {
    const div = document.createElement('div')    
    div.innerHTML = html
    return div
}

export function trim(node) {
    if (node.childNodes.length == 0) {
        return
    } else {
        for (var i = 0; i < node.childNodes.length; i++) {
            if (node.childNodes[i] instanceof Text) {
                const replacement = new Text(node.childNodes[i].wholeText.replace(/^\n|\n$/g, ''))
                node.replaceChild(replacement, node.childNodes[i])
            } else {
                trim(node.childNodes[i])
            }
        }
    }
}

export function renderMedia(tweet) {
    var tags = []
    if (tweet.media) {
        for (var media of tweet.media) {
            var thumb = media.replace(/\.\w+/i, ".jpg")
            if (/\.mp4$/i.exec(media)) {
                var woext = media.replace(/\.mp4/i, "")
                tags.push(`<video controls style="box-sizing: border-box; max-width: 100%; max-height: 360px" src="/standard/${woext}.mp4"></video>`)
            } else {
                tags.push(`<a href="/media/${media}"><img class="thumbnail" src="/thumbnails/${thumb}"></a>`)
            }
        }
    }
    return tags.join("")
}

export function renderDate(str) {
    // date +%Y%m%d%H%M
    var m
    m = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})$/.exec(str)
    if (m) {
        return `${m[4]}:${m[5]}\u2009·\u2009${m[1]}/${m[2]}/${m[3]}`;
    } else {
        return "[INVALID DATE]";
    }
}
