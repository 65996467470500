<template>
<!-- 投稿フォーム -->
<div v-cloak style="margin: 0.5em">
    <div>
        <textarea v-model="message" :disabled="isSending"></textarea>
    </div>
    <div style="margin: .5em 0">
        <label for="file">Media: </label>
        <input type="file"
               id="file"
               accept="image/png, image/jpeg, video/mp4"
               @change="onMediaFileSelected"
               :disabled="isSending">
    </div>
    <div>
        <img v-if="file && file.type.startsWith('image/')"
             :src="media_preview_url"
             style="max-width: 160px; max-height: 160px">
        <video v-if="file && file.type.startsWith('video/')"
               :src="media_preview_url"
               style="max-width: 160px; max-height: 160px">
        </video>
    </div>
    <div>
        <button @click="submitMessage" :disabled="isSending">投稿</button>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            message: "",
            media_preview_url: "",
            file: null,
            isSending: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const cb = function(event){
                console.log('cb called')
                var items = (event.clipboardData ||
                             event.originalEvent.clipboardData).items
                for (var index in items) {
                    var item = items[index]
                    if (item.kind === 'file') {
                        var blob = item.getAsFile()
                        var reader = new FileReader()
                        reader.onload = function(event){
                            vm.file = blob
                            vm.media_preview_url = event.target.result
                        }
                        reader.readAsDataURL(blob)
                    }
                }
            }
            document.onpaste = cb
            console.log('paste callback added')
            // vm.pasteCallback = cb
        })
    },
    beforeRouteLeave(to, from, next) {
        document.onpaste = null
        // document.removeEventListener('onpaste', this.pasteCallback)
        // console.log('paste callback removed')
        next()
    },
    methods: {
        onMediaFileSelected(ev) {
            var file = ev.target.files[0]
            console.log(file)
            this.file = file

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => { // 意図的なthisのキャプチャ。
                this.media_preview_url = reader.result
            }
        },
        clearForm() {
            // ただの保管場所。
            this.file = null

            this.message = ""
            this.media_preview_url = ""

            // unselect file
            document.querySelector("#file").value = ""
        },
        async submitMessage() {
            var r = await fetch('/login')
            if (r.status == 401) {
                alert("投稿するにはログインする必要があります。")
                return
            } else if (r.status != 200) {
                alert(`${r.status} error`)
                return
            }
            var form = new FormData()
            form.set('text', this.message)
            if (this.file)
                form.set('file', this.file)
            this.isSending = true
            fetch('/tweet', { method: 'POST', body: form }).then(async d =>  {
                this.isSending = false
                if (d.status == 200) {
                    this.clearForm()
                    this.$router.push("/")
                } else {
                    var text = await d.text()
                    alert(text)
                }
            })
        },
    }
}
</script>
