<style>
  .nav { position: fixed; left: 0; bottom: 0;
         width: 100vw; border-top: 1px solid rgba(0,0,0,0.2);
         height: 48px; background-color: #fff;
         padding-top: 12px;
         text-align: center;
         box-sizing: border-box;
         z-index: 1;
  }
  .nav > a {
      margin: 0 12px;
  }
  .nav img.emoji { width: 24px; height: 24px }
  .main-container {
    margin: auto;
    min-height: 100vh;
    max-width: 480px;
    padding-bottom: 49px;
    background-color: #fff;
  }

  @media (prefers-color-scheme: dark) {
      .nav {
          background-color: #222;
          border-top-color: rgba(255,255,255,0.2)
      }
      .main-container { background-color: #222 }
  }
</style>

<template>
<div class="main-container">
  <div class="toolbox">
    <span class="text-muted" style="font-weight: bold">
      流刑地日誌️
      <twemoji :codepoint="0x1f3dd" title="無人島"></twemoji>
      <twemoji :codepoint="0x1f41a" title="巻き貝"></twemoji>
    </span>
    <!--
        <a title="ログイン" class="icon-link" href="/login" style="float:right" v-if="!logged_in">&#x1F6AA;</a>
        -->
    <!--
        <br>
        <span style="color: gray; font-size: 85%">a derailed train of thoughts by a dejected dangling penis</span>
        -->
  </div>
  
  <div class="nav">
    <router-link to="/" style="" @click="reloadIfHome /*呼び出せてない*/">
      <twemoji :codepoint="0x1f3e0" title="ホーム"></twemoji>
    </router-link>
    <router-link to="/search" style="">
      <twemoji :codepoint="0x1f50d" title="検索"></twemoji>
    </router-link>
    <router-link to="/compose" style="">
      <twemoji :codepoint="0x2712" title="追加"></twemoji>
    </router-link>
    <router-link :to="'/u/' + encodeUserId(this.user_id)" style="">
      <twemoji :codepoint="0x1f464" title="プロフィール"></twemoji>
    </router-link>
    <a href="#" v-if="logged_in" @click.prevent="reloadServer" style="">
      <twemoji :codepoint="0x267b" title="サーバー再起動"></twemoji>
    </a>
  </div>
  
  
  <router-view></router-view>
  <!-- <router-view v-slot="{ Component }">
       <keep-alive>
         <component :is="Component"></component>
       </keep-alive>
  </router-view> -->
</div>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import TimelineComponent from './Timeline'
import ComposeComponent from './Compose'
import SearchComponent from './Search'
import EditComponent from './Edit'
import ReplyComponent from './Reply'
import SoloTweetViewComponent from './SoloTweetView'
import LikersComponent from './Likers'
import SharersComponent from './Sharers'
import LikesComponent from './Likes'
import NotFoundComponent from './NotFound'
import ProfileComponent from './Profile'
import ByYearComponent from './ByYear'
import ByYearIndexComponent from './ByYearIndex'

import { encodeUserId } from './utils'

const routes = [
    { path: '/',                   component: TimelineComponent      },
    { path: '/compose',            component: ComposeComponent       },
    { path: '/search',             component: SearchComponent        },
    { path: '/by-year/',           component: ByYearIndexComponent        },
    { path: '/by-year/:year',      component: ByYearComponent        },
    { path: '/status/:id',         component: SoloTweetViewComponent },
    { path: '/status/:id/likers',  component: LikersComponent        },
    { path: '/status/:id/sharers', component: SharersComponent       },
    { path: '/status/:id/edit',    component: EditComponent          },
    { path: '/status/:id/reply',   component: ReplyComponent         },
    { path: '/u/:id',              component: ProfileComponent       },
    { path: '/u/:id/likes',        component: LikesComponent         },
    
    // 上記以外は Not Found。
    { path: '/:pathMatch(.*)', component: NotFoundComponent }
]


const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

export default {
    router: router,
    created() {
        fetch("/api/whoami")
            .then(async r => {
                if (r.status != 200) {
                    console.error("whoami", r.status)
                } else {
                    const data = await r.json()
                    this.user_id = data["user_id"]
                    this.logged_in = data["logged_in"]
                }
            })
    },
    data() {
        return {
            user_id: null,
            logged_in: null,
        }
    },
    computed: {
        referrer() {
            return document.referrer
        },
    },
    methods: {
        reloadIfHome(ev) { // eslint-disable-line
            if (this.$route.fullPath == "/") {
                window.location = "/"
            }
        },
        reloadServer(_ev) { // eslint-disable-line
            fetch("/api/restart", { method: 'POST' }).then(res => {
                if (res.status == 200) {
                    if (confirm("Triggered server reload. Reload page as well?")) {
                        window.location = window.location // eslint-disable-line
                    }
                } else {
                    alert(`unexpected response ${res.status} from server`)
                }
            })
        },
        encodeUserId(id) { if (id) { return encodeUserId(id) } else { return "NULL" } },
    }
}

</script>
