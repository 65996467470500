<template>
<!-- ユーザー プロフィール ホーム -->
<div v-cloak>
    <h3 style="padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1); margin: 0; font-family: monospace">@{{ content_user_id }}</h3>
    <div style="padding: 0.5em; border-bottom: 1px solid rgba(0,0,0,0.1); margin: 0">
        <b>壺焼き</b>
        <router-link :to="'/u/' + encodeUserId(content_user_id) + '/likes'">いいね</router-link>
    </div>
    <div v-if="tweets.length == 0" style="padding: 0.5em">
        壺焼きはまだありません。
    </div>
    <tweet-list-item v-for="tweet in tweets" class="tweet" :key="tweet.id" :tweet="tweet" :likes="likes" :is_shared="isShared(tweet)"></tweet-list-item>
</div>
</template>

<script>
import { encodeUserId } from './utils'

export default {
    data () {
        return {
            content_user_id: "",
            tweets: [],
            likes: [],
        }
    },
    async beforeRouteEnter(to, from, next) {
        var likes = await (await fetch(`/likes`)).json()
        var shared = await (await fetch(`/api/u/${encodeURI(to.params.id)}/shared`)).json()
        // encodeUserId じゃないとだめじゃない？
        var kakikos = await (await fetch(`/api/u/${encodeURI(to.params.id)}/kakikos`)).json()

        var tweets = shared.tweets.concat(kakikos.tweets).sort((a,b) => +b.time - +a.time);
        next(vm => {
            vm.content_user_id = to.params.id.replace(/~/g, '/')
            vm.tweets = tweets
            vm.likes = likes
        })
    },
    methods: {
        encodeUserId: encodeUserId,
        isShared(tweet) {
            return tweet.shared_by && tweet.shared_by.includes(this.content_user_id)
        },
    },
}
</script>
